var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'list-card': true,
    'dragg-item': _vm.fixed !== true,
    'card-fixed': _vm.fixed,
  },style:({
    'min-height': _vm.type === 'teaching-design' ? '50px' : '24px',
    'border-left-color': _vm.getColorByType(_vm.item.type),
  }),attrs:{"id":_vm.id}},[_c('div',{class:{ 'card-content': true }},[(_vm.type === 'teaching-design' && _vm.fixed !== true)?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.swapItem.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-swap-horizontal")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.item.title)+" "),_vm._l((_vm.item.lines),function(x){return _c('p',{key:x},[_vm._v(" ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }